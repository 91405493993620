const SELECTION_ITEM_NAME = 'selection';

let localStorageItem = localStorage.getItem(SELECTION_ITEM_NAME);
const idsByType = localStorageItem
  ? JSON.parse(localStorageItem)
  : {
      illustration: [],
      dossier: [],
    };

// Ajoute ou retire l'élément de la sélection.
// Retourne true/false selon que l'élément ait été ajouté ou non
export function toggleSelection(type, id, value) {
  const idx = idsByType[type].indexOf(id);
  let selected;
  if (idx === -1) {
    // on ne fait pas if (!value) pour ne pas prendre en compte la valeur undefined
    if (value === false) {
      selected = false;
    } else {
      idsByType[type].push(id);
      localStorage.setItem(SELECTION_ITEM_NAME, JSON.stringify(idsByType));
      selected = true;
    }
  } else if (!value) {
    idsByType[type].splice(idx, 1);

    localStorage.setItem(SELECTION_ITEM_NAME, JSON.stringify(idsByType));
    selected = false;
  } else {
    selected = !!value;
  }

  const event = new CustomEvent('selection:changed', {
    detail: { type, id, selected },
  });
  document.dispatchEvent(event);

  return selected;
}

export function isInSelection(type, id) {
  return idsByType[type].indexOf(id) !== -1;
}

export function manageButton(btnElement) {
  const type = btnElement.getAttribute('data-type');
  const id = btnElement.getAttribute('data-id');

  let selected = isInSelection(type, id);
  if (selected) {
    btnElement.classList.toggle('en-selection', selected);
  }

  btnElement.addEventListener('click', () => {
    toggleSelection(type, id);
  });

  const onSelectionChanged = ({ detail }) => {
    if (type !== detail.type || id !== detail.id) {
      return;
    }
    if (!document.body.contains(btnElement)) {
      // cela signifie que le bouton a été supprimé du DOM précédemment
      // on supprime le listener
      document.removeEventListener('selection:changed', onSelectionChanged);
      return;
    }

    btnElement.classList.toggle('en-selection', detail.selected);
  };
  document.addEventListener('selection:changed', onSelectionChanged);
}

export function getSelection() {
  return idsByType;
}

export function manageSelectionLinkInHeader() {
  const selectionCountElement = document.querySelector(
    '.header-bandeau-ma-selection-count',
  );
  if (!selectionCountElement) {
    return;
  }

  document.addEventListener('selection:changed', () =>
    updateSelectionCount(selectionCountElement),
  );
  updateSelectionCount(selectionCountElement);
}

const headerBandeauMaSelection = document.getElementById(
  'headerBandeauMaSelection',
);

function updateSelectionCount(selectionCountElement) {
  const count = Object.keys(idsByType)
    .map((key) => idsByType[key].length)
    .reduce((sum, listSize) => sum + listSize, 0);
  selectionCountElement.innerHTML = ` (${count})`;

  if (count > 0) {
    headerBandeauMaSelection.classList.add('has-selection');
  } else {
    headerBandeauMaSelection.classList.remove('has-selection');
  }
}
