/**
 * Check si la propriété CSS line-clamp est active ou non.
 *
 * Cela peut être déterminé en comparant scrollHeight avec clientHeight :
 * dans le cas ou la propriété n'est pas active, les deux auront la même valeur;
 * dans le cas contraire clientHeight aura pour valeur la taille finale du bloc tronqué et scrollHeight la taille du bloc non tronqué
 * (comme si la propriété line-clamp n'existait pas)
 *
 * Workaround: il est possible que scrollHeight > clientHeight génère des faux positifs.
 * il est possible dans certains cas que scrollHeight soit supérieur à clientHeight de 1 pixel.
 * Prémunissons de ce genre de cas en laissant la possibilité que les deux valeurs puissent être légèrement différentes.
 */
const isElementLineClamped = (element) =>
  Math.abs(element.scrollHeight - element.clientHeight) > 1;

const toggleShowAllElement = (showAllElement, checked) => {
  showAllElement.dataset.open = checked;

  const labelElement = showAllElement.querySelector(
    '.line-clamped-show-all-label',
  );
  if (checked) {
    labelElement.innerHTML = 'Voir moins';
  } else {
    labelElement.innerHTML = 'Voir tout';
  }
};

function getInputElement(showAllElement) {
  return showAllElement?.querySelector('input[type="checkbox"]');
}

export const getShowAllElement = (lineClampedElement) =>
  lineClampedElement?.parentElement?.querySelector('.line-clamped-show-all');

/**
 * Limite la taille d'un paragraphe à maxLines lignes.
 * Si le paragraphe dépasse le nombre de lignes, le texte sera tronqué et on y ajoutera automatiquement "..." à la fin.
 * Si un bouton "Voir tout" est associé au paragraphe, il permettra de désactiver le tronquage et de le réactiver.
 *
 * Dans le cas où les éléments enfants posent problème avec clampLines (comme un texte riche avec plein de paragraphes, de titres, etc.),
 * passer le paramètre force à true pour modifier les éléments enfants et avoir un affichage simplifié (display: inline, etc.)
 */
export const clampLines = (
  lineClampedElement,
  maxLines,
  force = false,
  showAllElement = getShowAllElement(lineClampedElement),
) => {
  if (lineClampedElement == null) {
    return {};
  }

  if (force) {
    Array.from(lineClampedElement.children).forEach((child) =>
      child.classList.add('line-clamp-fixed-subelement'),
    );
  }

  lineClampedElement.classList.add('line-clamped');

  lineClampedElement.style.webkitLineClamp = maxLines;

  if (showAllElement) {
    const inputElement = getInputElement(showAllElement);
    inputElement.addEventListener('change', () => {
      const currentPosition = window.scrollY;
      lineClampedElement.style.webkitLineClamp = inputElement.checked
        ? ''
        : maxLines;
      lineClampedElement.classList.toggle('opened', inputElement.checked);

      if (!inputElement.checked) {
        // Il est possible que fermer l'élément rende l'élément initial non visible à l'écran
        // On revient à la position d'avant
        const elementTop = lineClampedElement.getBoundingClientRect().top;
        const headerHeight = document.querySelector('header').offsetHeight;
        if (elementTop < headerHeight || elementTop > window.innerHeight) {
          window.scroll({
            top: lineClampedElement.offsetTop,
            behavior: 'smooth',
          });
        }
      } else {
        // Dans le cas d'écrans de smartphone, cliquer sur "Voir tout" amère l'utilisateur à la fin du bloc
        // (au lieu de le laisser là où il était)
        // On force donc le positionnement de l'utilisateur à l'endroit où il était juste avant de cliquer sur le bouton.
        window.scroll({ top: currentPosition });
      }

      toggleShowAllElement(showAllElement, inputElement.checked);
    });

    // On s'assure que les clics sur les liens vers les footnotes dans les textes riches ouvrent automatiquement le panneau
    lineClampedElement
      .querySelectorAll('.text .note')
      .forEach((noteElement) => {
        noteElement.addEventListener('click', () => {
          openClampLines();
        });
      });

    checkShowAllElement();

    // On s'assure que le redimensionnement de l'écran ou d'un composant à proximité
    // et donc l'augmentation ou la diminution du nombre de lignes de l'élément rende l'élément tronqué ou non
    // ce qui affectera le bouton "Voir plus"
    let currentTimeout;
    const resizeObserver = new ResizeObserver(() => {
      // On s'assure que la fonction checkShowAllElement ne soit pas appelée des centaines de fois en moins d'une seconde
      if (currentTimeout) {
        window.clearTimeout(currentTimeout);
      }

      currentTimeout = setTimeout(() => checkShowAllElement(), 200);
    });
    resizeObserver.observe(lineClampedElement);
  }

  function checkShowAllElement() {
    if (showAllElement.dataset.limitedLines) {
      return;
    }
    if (showAllElement.dataset.open === 'true') {
      return;
    }

    showAllElement.style.display = isElementLineClamped(lineClampedElement)
      ? 'flex'
      : 'none';
  }

  function openClampLines() {
    const inputElement = getInputElement(showAllElement);
    lineClampedElement.style.webkitLineClamp = '';
    inputElement.checked = true;
    toggleShowAllElement(showAllElement, inputElement.checked);
  }

  return { openClampLines };
};

/**
 * Limite le nombre d'éléments (= <li>) dans une liste (= <ol> ou <ul>).
 * Si la liste dépasse le nombre d'entrées, les éléments en trop seront cachés.
 * Si un bouton "Voir tout" est associé à la liste, il permettra de désactiver la limitation et de le réactiver.
 */
export const limitListElements = (
  listElement,
  maxLines,
  showAllElement = getShowAllElement(listElement),
) => {
  const elements = Array.from(listElement.children).filter((child) =>
    child.matches('li'),
  );
  if (elements.length <= maxLines) {
    return;
  }

  const hiddenElements = Array.from(elements).slice(maxLines, elements.length);
  const oldDisplayValue = getComputedStyle(hiddenElements[0]).display;

  if (showAllElement) {
    showAllElement.dataset.limitedLines = maxLines;
    showAllElement.style.display = 'flex';

    // On cache les éléments en trop en premier
    hiddenElements.forEach((element) => (element.style.display = 'none'));

    const inputElement = getInputElement(showAllElement);
    inputElement.addEventListener('change', () => {
      // On cache / affiche les éléments selon la valeur de l'input
      hiddenElements.forEach(
        (element) =>
          (element.style.display = inputElement.checked
            ? oldDisplayValue
            : 'none'),
      );

      toggleShowAllElement(showAllElement, inputElement.checked);
    });
  }
};

export function addParamsToUrl(url, params) {
  const hasParams = url.indexOf('?') !== -1;
  const searchParams = new URLSearchParams();
  for (const key in params) {
    if (Object.prototype.hasOwnProperty.call(params, key) && params[key]) {
      searchParams.set(key, params[key]);
    }
  }
  return `${url}${hasParams ? '&' : '?'}${searchParams.toString()}`;
}

export function waitForImageLoaded(
  imageElement,
  successCallback,
  errorCallback,
) {
  if (isImageLoaded(imageElement)) {
    successCallback?.();
  } else if (imageElement.complete) {
    // L'image est en erreur
    errorCallback?.();
  } else {
    if (successCallback) {
      imageElement.addEventListener('load', () => successCallback?.());
    }
    if (errorCallback) {
      imageElement.addEventListener('error', () => errorCallback?.());
    }
  }
}

function isImageLoaded(imageElement) {
  return (
    imageElement.complete &&
    imageElement.naturalWidth &&
    imageElement.naturalHeight
  );
}

export function avoidUpscalingImage(imageElement, resizeCallback = () => {}) {
  waitForImageLoaded(imageElement, () => {
    checkImageSize(imageElement, resizeCallback);

    // On surveille le redimmensionnement de la page
    let currentTimeout;
    window.addEventListener('resize', () => {
      if (currentTimeout) {
        // On évite d'appeler la fonction checkImageSize trop souvent
        window.clearTimeout(currentTimeout);
      }

      currentTimeout = setTimeout(
        () => checkImageSize(imageElement, resizeCallback),
        300,
      );
    });
  });
}

export function checkImageInError(imageElement, parentSelector) {
  waitForImageLoaded(
    imageElement,
    () => {
      /* NOOP */
    },
    () => {
      imageElement.style.display = 'none';
      imageElement
        .closest(parentSelector)
        .querySelector('.image-indispo').style.display = 'initial';
    },
  );
}

function checkImageSize(imageElement, resizeCallback) {
  const realWidth = imageElement.naturalWidth;
  const realHeight = imageElement.naturalHeight;
  const currentWidth = imageElement.offsetWidth;
  const currentHeight = imageElement.offsetHeight;
  if (currentWidth > realWidth || currentHeight > realHeight) {
    imageElement.style.maxWidth = `${realWidth}px`;
    imageElement.style.maxHeight = `${realHeight}px`;
    resizeCallback?.();
  } else if (imageElement.style.maxWidth || imageElement.style.maxHeight) {
    // On s'assure de ne pas laisser de règle superflue qui pourrait rentrer en conflit avec le CSS de la page
    // (ce qui peut arriver si le CSS définit déjà une max-width se basant sur la taille de la viewport)
    imageElement.style.maxWidth = '';
    imageElement.style.maxHeight = '';
    resizeCallback?.();
  }
}
