import './accueil.css';
import '../dossiers-carousel/dossiers-carousel.css';
import '../utils/utils.css';

import { initDossiersCarousel } from '../dossiers-carousel/dossiers-carousel.js';

import { clampLines, avoidUpscalingImage } from '../utils/utils.js';
import { manageSelectionLinkInHeader } from '../selection/utils.js';

initDossiersCarousel(document.querySelector('.accueil .dossiers-carousel'));

const editoElement = document.querySelector('.accueil .accueil-edito-content');
if (editoElement) {
  clampLines(editoElement, 6, true);
}

document
  .querySelectorAll('.popup-wrap h3, .accueil-lumiere-sur-content-text-title')
  .forEach((title) => clampLines(title, 2));

const lumiereSurTexteLibre = document.querySelector(
  '.accueil-lumiere-sur-texte-libre',
);
if (lumiereSurTexteLibre) {
  clampLines(lumiereSurTexteLibre, 4, true);
}

manageSelectionLinkInHeader();

const imageElements = document.querySelectorAll(`
  .accueil-image-du-jour img,
  .accueil-lumiere-sur-content-image img
`);

imageElements.forEach((imageElement) => avoidUpscalingImage(imageElement));
