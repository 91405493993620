import { checkImageInError, clampLines } from '../utils/utils.js';

const checkPaginationButtons = (carousel) => {
  const currentOffset = parseInt(carousel.getAttribute('data-offset') || 0);
  const elements = carousel.querySelectorAll('.dossiers-carousel-element');
  const columnCount = Math.min(
    elements.length,
    parseInt(
      getComputedStyle(carousel).getPropertyValue(
        '--dossiers-carousel-column-number',
      ),
    ),
  );

  carousel.querySelector('[data-pagination="previous"]').disabled =
    currentOffset === 0;
  carousel.querySelector('[data-pagination="next"]').disabled =
    currentOffset === -1 * (elements.length - columnCount);
};

export const initDossiersCarousel = (carousel) => {
  if (carousel == null) {
    return;
  }

  carousel
    .querySelectorAll(
      '.dossiers-carousel-element-tico a, .dossiers-carousel-element-localisation',
    )
    .forEach((element) => clampLines(element, 2));

  checkPaginationButtons(carousel);

  const container = carousel.querySelector('.dossiers-carousel-container');
  const elements = container.querySelectorAll('.dossiers-carousel-element');
  const columnGap = parseInt(getComputedStyle(container).columnGap);

  carousel.querySelectorAll('[data-pagination]').forEach((btn) => {
    let incrementValue;
    if (btn.getAttribute('data-pagination') === 'next') {
      incrementValue = -1;
    } else {
      incrementValue = 1;
    }

    btn.addEventListener('click', () => {
      const currentOffset = parseInt(carousel.getAttribute('data-offset') || 0);
      const newOffset = currentOffset + incrementValue;
      carousel.setAttribute('data-offset', newOffset);

      elements.forEach((element) => {
        const elementWidth = element.clientWidth;
        element.style.transform = `translate3d(${
          (elementWidth + columnGap) * newOffset
        }px, 0, 0)`;
      });

      checkPaginationButtons(carousel);
    });
  });

  carousel
    .querySelectorAll('.dossiers-carousel-element img')
    .forEach((vignette) =>
      checkImageInError(vignette, '.dossiers-carousel-element'),
    );
};
